import { addons } from "@storybook/manager-api";
import { create } from "@storybook/theming";

addons.setConfig({
  panelPosition: "right",
  theme: create({
    base: "dark",
    brandTitle: "ZoraKit",
    brandUrl: "https://zora.co",
    brandImage: "./logo.svg",
    brandTarget: "_self",
  }),
});
